<template>
  <div class="mt-20 w-full">
    <img
      src="~/assets/images/logo.svg"
      alt=""
      class="mx-auto mb-12"
    >
    <div class="text-center">
      <h1 class="mb-2.5 text-2xl">
        Log in bij Entercare portal
      </h1>
      <!-- TODO: Add final text -->
      <!-- <p>Viverra tristique turpis sed et et ultrices. Ut pellentesque tortor risus.</p> -->
      <!-- <hr class="mb-5 mt-10"> -->
    </div>
    <FormKitSchema
      v-bind="formSchema"
    />
    <div class="mt-2">
      <NuxtLink
        class="text-xs no-underline hover:underline"
        :to="{name: 'auth-wachtwoord-vergeten'}"
      >
        Wachtwoord vergeten?
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'auth',
  auth: {
    unauthenticatedOnly: true,
  },
});

const formSchema = useSofieLoginSchema();
</script>
